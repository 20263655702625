import SocialIcons from "./SocialIcons";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="p-4 pt-16 pb-16 max-w-screen-xl m-auto text-center text-gray-700">
          <div>
            Portfolio of John Okely, software engineering leader from Perth,
            Australia. I work with Scrum, Shape Up, Typescript, React.js,
            Node.js and more.
          </div>
          <div className="m-auto mt-2">
            <SocialIcons size="small" />
          </div>
        </div>
      </footer>
    </>
  );
}
