import Head from "next/head";

import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children, pageTitle, description, ...props }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <meta name="Description" content={description}></meta>
        <link
          rel="alternate"
          type="application/rss+xml"
          href="https://johnok.com/feed.xml"
          title="John Okely - Blog RSS Feed"
        />
        <title>{pageTitle}</title>
      </Head>
      <section>
        <Header />
        {children}
        <Footer />
      </section>
    </>
  );
}
