import { useRouter } from "next/router";
import Image from "next/image";

import Anchor from "./Util/Anchor";
import profilePic from "../public/img/avatar.jpeg";
import { imageLoader } from "./imageLoader";

export default function Header() {
  const router = useRouter();
  return (
    <>
      <header className="border-t-4 border-blue-500 bg-white shadow-md">
        <nav
          className="flex items-center justify-between flex-wrap p-4 max-w-screen-xl m-auto"
          role="navigation"
          aria-label="main navigation"
        >
          <div className="flex items-center mr-6 mb-4 md:mb-0">
            <Anchor href="/">
              <span className="font-bold">
                <div className="h-12 w-12 mr-4">
                  <Image
                    src={profilePic}
                    width={48}
                    height={48}
                    layout="responsive"
                    className="rounded-full"
                    alt="John Okely"
                    loader={imageLoader}
                  />
                </div>
              </span>
            </Anchor>
            <Anchor href="/">
              <span className="font-bold text-lg">John Okely</span>
            </Anchor>
          </div>
          <div className="flex items-center">
            <Anchor href="/OKELY John Resume 2023.pdf">
              <span className="font-semibold  md:ml-6">Resume</span>
            </Anchor>
            <Anchor href="/portfolio/">
              <span
                className={`font-semibold ml-6 ${
                  router.pathname === "/portfolio"
                    ? "font-bold border-b-4 border-blue-500"
                    : "font-semibold"
                }`}
              >
                Portfolio
              </span>
            </Anchor>
            <Anchor href="/blog/">
              <span
                className={`font-semibold ml-6 ${
                  router.pathname === "/blog"
                    ? "font-bold border-b-4 border-blue-500"
                    : "font-semibold"
                }`}
              >
                Blog
              </span>
            </Anchor>
            <Anchor href="/music/">
              <span
                className={`font-semibold ml-6 ${
                  router.pathname === "/music"
                    ? "font-bold border-b-4 border-blue-500"
                    : "font-semibold"
                }`}
              >
                Music
              </span>
            </Anchor>
          </div>
        </nav>
      </header>
    </>
  );
}
