import Layout from "@components/Layout";
import PostList from "@components/PostList";
import Company from "@components/Company";
import SocialIcons from "@components/SocialIcons";
import ConferenceSpeaking from "@components/HomePage/ConferenceSpeaking";
import Qualificiations from "@components/HomePage/Qualificiations";

import getPosts from "@utils/getPosts";
import Anchor from "@components/Util/Anchor";

import functionlyLogo from "../public/img/functionly-logo.svg";
import canvaLogo from "../public/img/canva-logo.svg";
import healthEngineLogo from "../public/img/healthengine-logo.png";
import moodleLogo from "../public/img/moodle-logo.png";
import iiNetLogo from "../public/img/iinet-logo.png";
import theFatheringProjectLogo from "../public/img/fathering-project-logo.jpg";
import { generateRSSFeed } from "@utils/getRss";

const Home = ({ posts, title, description, ...props }) => {
  return (
    <>
      <Layout pageTitle={title} description={description}>
        <div className="bg-me bg-cover bg-center">
          <div className="h-screen text-center max-w-screen-xl m-auto pt-8">
            <div className="pt-4 pb-4 pl-8 pr-8 bg-white inline-block rounded-full shadow-lg">
              <h1 className="lg:text-6xl text-2xl font-semibold">
                Hey, I'm <span className="text-blue-500">John&nbsp;Okely</span>.
              </h1>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl m-auto md:flex px-4 py-8 md:p-16">
          <div className="md:mr-16">
            <p className="mb-4 text-lg">
              I'm an <span className="font-semibold">Engineering Manager</span>{" "}
              at{" "}
              <Anchor href="https://canva.com/">
                <span className="text-blue-500 hover:text-blue-600 font-semibold">
                  Canva
                </span>
              </Anchor>
              .
            </p>
            <div>
              <p className="mb-4 text-gray-700">
                I enjoy building and improving software products, developing and
                leading teams.
              </p>
              <p className="mb-4 text-gray-700">
                Want to see what I make in my spare time? View my{" "}
                <Anchor href="/portfolio">
                  <span className="text-blue-500 hover:text-blue-600 font-semibold">
                    portfolio
                  </span>
                </Anchor>
                .
              </p>
            </div>
          </div>
          <div>
            <span className="text-blue-300">
              <SocialIcons />
            </span>
            <Anchor href="/blog">
              <h2 className="text-lg mb-2 font-semibold mt-8">Articles</h2>
            </Anchor>
            <div className="ml-4 font-semibold text-blue-300">
              <PostList posts={posts} />
            </div>
          </div>
        </div>
        <div className="bg-blue-300">
          <div className="max-w-screen-xl m-auto px-4 py-8 md:p-16 text-blue-900">
            <h2 className="text-2xl font-bold text-center mb-4 md:mb-16 text-white">
              Interests
            </h2>
            <div className="md:flex">
              <div className="flex-grow">
                <h3 className="text-lg mb-4 font-semibold ml-4">Tech</h3>
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Game development &amp; gamification
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
                      />
                    </svg>
                    Frontend web development
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path
                        fillRule="evenodd"
                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Virtual reality
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    Automated testing
                  </li>
                </ul>
              </div>
              <div className="flex-grow">
                <h3 className="text-lg mb-4 font-semibold ml-4 mt-8 md:mt-0">
                  Other
                </h3>
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M18 3a1 1 0 00-1.196-.98l-10 2A1 1 0 006 5v9.114A4.369 4.369 0 005 14c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V7.82l8-1.6v5.894A4.37 4.37 0 0015 12c-1.657 0-3 .895-3 2s1.343 2 3 2 3-.895 3-2V3z" />
                    </svg>
                    Piano
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Music production
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                      />
                    </svg>
                    Doctor Who
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Body weight exercises
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                      <path
                        fillRule="evenodd"
                        d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Fiction &amp; script writing
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2h-1.586a1 1 0 01-.707-.293l-1.121-1.121A2 2 0 0011.172 3H8.828a2 2 0 00-1.414.586L6.293 4.707A1 1 0 015.586 5H4zm6 9a3 3 0 100-6 3 3 0 000 6z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Photography &amp; cinematography
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                    Growing food
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl m-auto py-16 px-2 lg:px-16 flex flex-col">
          <h2 className="text-2xl font-bold text-center mb-4 md:mb-16">
            Background
          </h2>
          <div className="flex flex-wrap">
            <div className="flex-grow w-64">
              <Company
                url="http://canva.com/"
                logo={canvaLogo}
                companyName="Canva"
                title="Engineering Manager"
                duration="Current"
                responsibilities={[
                  "Building out frontend for external extensions API",
                  "Typescript React.js",
                ]}
              />
            </div>
            <div className="flex-grow w-64">
              <Company
                url="http://functionly.com/"
                logo={functionlyLogo}
                companyName="Functionly"
                title="Head of Engineering"
                duration="2019-2022"
                responsibilities={[
                  "Building a brand new engineering org from the ground up",
                  "Typescript React.js & Node.js",
                  "Growth modelling, analysis & A/B testing",
                ]}
              />
            </div>
            <div className="flex-grow w-64">
              <Company
                url="http://healthengine.com.au/"
                logo={healthEngineLogo}
                companyName="HealthEngine"
                title="Engineering Manager"
                duration="2017-2019"
                responsibilities={[
                  "Scrum & Kanban",
                  "Radical Candour",
                  "Teams of 3-9",
                  "PHP, React.js & Typescript",
                ]}
              />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="flex-grow w-64">
              <Company
                url="http://moodle.com/"
                logo={moodleLogo}
                companyName="Moodle"
                title="Analyst Developer"
                duration="2014-2017"
                responsibilities={[
                  "Backend PHP & SQL",
                  "Frontend JQuery, mustache, AMD",
                  "Review of contributed code (open source)",
                  "Presentations of new features & new ideas at Moodle conferences",
                ]}
              />
            </div>
            <div className="flex-grow w-64">
              <Company
                url="https://www.iinet.net.au/"
                logo={iiNetLogo}
                companyName="iiNet"
                title="Associate Programmer"
                duration="2013-2014"
                responsibilities={[
                  "Backend development - Perl & Enterprise Messaging Systems",
                  "Systems analysis",
                  "Requirements gathering",
                  "Working with large legacy codebase",
                ]}
              />
            </div>
            <div className="flex-grow w-64">
              <Company
                url="http://thefatheringproject.org/"
                logo={theFatheringProjectLogo}
                companyName="The Fathering Project"
                title="SEO Specialist/Content Manager"
                duration="2011-2013"
                responsibilities={[
                  "Wordpress",
                  "Search Engine Optimisation (SEO)",
                  "Graphic Design",
                  "PHP Programming",
                ]}
              />
            </div>
          </div>
        </div>

        <div className="bg-blue-300">
          <div className="max-w-screen-xl m-auto text-blue-900 px-4 py-8 md:p-16">
            <h2 className="text-2xl font-bold text-center mb-4 md:mb-16 text-white">
              Skills
            </h2>
            <div className="flex flex-wrap">
              <div className="flex-grow">
                <h3 className="text-lg mb-4 font-semibold ml-4 text-white">
                  Tools I use
                </h3>
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    VS Code
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Git
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
                    </svg>
                    Amplitude (product/growth analytics)
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Atlassian suite
                  </li>
                </ul>
                <h3 className="text-lg mb-4 font-semibold ml-4 mt-8 text-white">
                  Other Skills
                </h3>
                <ul>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 inline mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                      />
                    </svg>
                    Shape Up
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M2 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V4zM8 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1H9a1 1 0 01-1-1V4zM15 3a1 1 0 00-1 1v12a1 1 0 001 1h2a1 1 0 001-1V4a1 1 0 00-1-1h-2z" />
                    </svg>
                    Scrum
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                    </svg>
                    Team Leadership
                  </li>
                  <li>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 inline mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    Public speaking
                  </li>
                </ul>
              </div>
              <div className="flex-grow">
                <h3 className="text-lg mt-8 md:mt-0 mb-4 font-semibold ml-4 text-white">
                  Development
                </h3>
                <ul className="list-disc">
                  <li>Typescript / Javascript</li>
                  <li>React.js</li>
                  <li>Svelte</li>
                  <li>Node.js</li>
                  <li>GraphQL</li>
                  <li>SQL</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="max-w-screen-xl m-auto px-4 py-8 md:p-16"
          id="conference-speaking"
        >
          <ConferenceSpeaking />
        </div>
        <div className="bg-blue-300">
          <div className="max-w-screen-xl m-auto px-4 py-8 md:p-16 text-blue-900">
            <Qualificiations />
          </div>
        </div>
        <div className="bg-contact bg-cover bg-center">
          <div className="text-center max-w-screen-xl m-auto pt-16 pb-32">
            <h2 className="lg:text-4xl text-2xl font-bold">
              Get in touch to discuss opportunities or ideas.
            </h2>
            <div className="m-auto mt-8">
              <SocialIcons />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;

export async function getStaticProps() {
  const configData = await import(`../siteconfig.json`);

  const posts = ((context) => {
    return getPosts(context);
  })((require as any).context("../posts", true, /^posts\/.*\.md$/));

  generateRSSFeed(posts);

  return {
    props: {
      posts,
      title: configData.default.title,
      description: configData.default.description,
    },
  };
}
