import Anchor from "./Util/Anchor";

export default function PostList({ posts }) {
  if (posts === "undefined") return null;

  return (
    <div>
      {!posts && <div>No posts!</div>}
      <ul>
        {posts &&
          [...posts].reverse().map((post) => {
            return (
              <li key={post.slug}>
                <Anchor href={`/post/${post.slug}`}>
                  {post?.frontmatter?.title}
                </Anchor>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
