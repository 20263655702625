// https://johnok-com.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fimage%2Fpublic%2Fimg%2Fmoodle-logo.8b1af4400fb60c8590d8eb0c8f860002.png&w=640&q=75

let imageLoader;

if (process.env.FOR_EXPORT) {
  imageLoader = ({ src, width, quality }) => {
    return `https://johnok-com.vercel.app/_next/image?url=${encodeURIComponent(
      src
    )}&w=${width}&q=${quality || 75}`;
  };
} else {
  imageLoader = undefined;
}

export { imageLoader };
