import Image from "next/image";

import { imageLoader } from "../imageLoader";
import csmIcon from "../../public/img/seal-csm.png";

const Qualifications = () => {
  return (
    <>
      <h3 className="text-2xl font-bold text-center mb-4 md:mb-16 text-white">
        Qualifications
      </h3>
      <div className="md:flex">
        <div className="flex-grow-1 md:w-1/2 mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-32 m-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <h4 className="text-lg mb-4 font-semibold">Computer Science</h4>
          <p>
            Murdoch's Computer Science course provides students with a thorough
            understanding of the theory, methods and systems used by the
            information technology industry, with a particular focus on the
            application of computer systems and software used in problem solving
            complex issues.
          </p>
        </div>
        <div className="flex-grow-1 md:w-1/2 mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-32 m-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          <h4 className="text-lg mb-4 font-semibold">
            Business Administration
          </h4>
          <p>
            Murdoch offers a Masterclass course to the top 10% of students
            across all disciplines. The course studies a Post Graduate
            Certificate in Business Administration, and it is designed to
            develop the analytical skills that are needed to make reliable
            business decisions, and provide insight into the way organisations
            are managed.
          </p>
        </div>
      </div>
      <div className="md:flex">
        <div className="flex-grow-1 md:w-1/2 mt-8">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-32 m-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
            />
          </svg>
          <h4 className="text-lg mb-4 font-semibold">Games Technology</h4>
          <p>
            Murdoch's Games Technology course provides students with a thorough
            understanding of the theory, design and programming techniques
            needed to produce all kinds of software including games.
          </p>
        </div>
        <div className="flex-grow-1 md:w-1/2 mt-8">
          <div className="text-center">
            <Image
              src={csmIcon}
              alt="Certified ScrumMaster Seal"
              height={160}
              width={160}
              className="h-40 m-auto"
              loader={imageLoader}
            />
          </div>
          <h4 className="text-lg mb-4 font-semibold">Certified ScrumMaster</h4>
          <p>
            The Certified ScrumMaster is the most globally recognised Agile
            certification, governed by the Scrum Alliance. This certification
            demonstrates a clear understanding of Scrum values, key Scrum
            practices and how to apply them. A Certified ScrumMaster helps Scrum
            Teams perform at their highest level.
          </p>
        </div>
      </div>
    </>
  );
};

export default Qualifications;
