import Image from "next/image";

import Anchor from "@components/Util/Anchor";

import startupWeekendPhoto from "../../public/img/sw-scraply.jpg";
import mootAu2017Photo from "../../public/img/speaking-VR.jpg";
import mootUs2016Photo from "../../public/img/speaking-LTI.jpg";
import mootAu2015Photo from "../../public/img/speaking-gamification.jpg";
import { imageLoader } from "../imageLoader";

interface ITalkProps {
  event: string;
  image: StaticImageData;
  location: string;
  topic: string;
  watch: string;
  watchHint?: string;
  watch2?: string;
  watch2Hint?: string;
  slides?: string;
}

const Talk = ({
  event,
  image,
  location,
  topic,
  watch,
  watchHint,
  watch2,
  watch2Hint,
  slides,
}: ITalkProps) => {
  const defaultLink = watch;
  const aspectRatio = image.height / image.width;
  const width = 384;
  const height = width * aspectRatio;
  return (
    <div className="bg-white border rounded-lg w-full sm:w-64 lg:w-full lg:max-w-xs xl:max-w-sm shadow-lg mb-8 overflow-hidden inline-block mr-4 h-64">
      <div className="h-32 overflow-hidden">
        <Anchor href={defaultLink}>
          <div className="object-cover">
            <span className="hidden">{event}</span>
            <Image
              src={image}
              height={height}
              width={width}
              alt={event}
              layout="responsive"
              loader={imageLoader}
            />
          </div>
        </Anchor>
      </div>
      <div className="p-4">
        <Anchor href={defaultLink}>
          <div className="text-gray-700 text-xs uppercase font-semibold tracking-wide">
            {event} - {location}
          </div>
          <h4 className="font-semibold text-lg mb-4">{topic}</h4>
        </Anchor>
        <div className="font-semibold text-blue-500 text-sm flex">
          <span className="flex-grow hover:text-blue-600">
            <Anchor href={watch}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-4 inline mr-1"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                  clipRule="evenodd"
                />
              </svg>
              Watch
            </Anchor>
            {watchHint && (
              <div className="text-gray-700 text-xs">{watchHint}</div>
            )}
          </span>
          {watch2 && (
            <span className="flex-grow hover:text-blue-600">
              <Anchor href={watch2}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 inline mr-1"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
                Watch
              </Anchor>
              {watch2Hint && (
                <div className="text-gray-700 text-xs">{watch2Hint}</div>
              )}
            </span>
          )}
          {slides && (
            <span className="flex-grow hover:text-blue-600">
              <Anchor href={slides}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-4 inline mr-1"
                >
                  <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                </svg>
                Slides
              </Anchor>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const ConferenceSpeaking = () => {
  return (
    <div>
      <h3 className="text-2xl font-bold text-center mb-4 md:mb-16">
        Conference Speaking
      </h3>
      <Talk
        event="Startup Weekend"
        image={startupWeekendPhoto}
        location="Perth"
        topic="Scraply"
        watch="https://www.youtube.com/watch?v=fSVTEj1wLtc"
        slides="https://www.slideshare.net/JohnOkely/scraply-startup-weekend-perth"
      />
      <Talk
        event="Moodle Moot AU 2017"
        image={mootAu2017Photo}
        location="Sydney"
        topic="Virtual Reality in Education"
        watch="https://www.youtube.com/watch?v=cnCqNpWarFI"
        slides="https://www.slideshare.net/JohnOkely/virtual-reality-in-education-80329511"
      />
      <Talk
        event="Moodle Moot US 2016"
        image={mootUs2016Photo}
        location="Los Angeles"
        topic="The Future of Education - LTI"
        watch="https://www.youtube.com/watch?v=D8svg3KAAho"
        watchHint="Sydney encore"
        slides="https://www.slideshare.net/JohnOkely/future-of-education-lti"
      />
      <Talk
        event="Moodle Moot AU 2015"
        image={mootAu2015Photo}
        location="Melbourne"
        topic="Gamify your Moodle"
        watch="https://www.youtube.com/watch?v=7l--OE1-pTk"
        watchHint="webinar version"
        watch2="https://www.youtube.com/watch?v=g1UGF-OBjMc"
        watch2Hint="audio &amp; tweets"
      />
    </div>
  );
};

export default ConferenceSpeaking;
