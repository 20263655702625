import Image from "next/image";
import Anchor from "./Util/Anchor";
import { imageLoader } from "./imageLoader";

interface IProps {
  url: string;
  logo: StaticImageData;
  companyName: string;
  title: string;
  duration: string;
  responsibilities: string[];
}

const Company = ({
  url,
  logo,
  companyName,
  title,
  duration,
  responsibilities,
}: IProps) => {
  const aspectRatio = logo.width / logo.height;
  const height = 64;
  return (
    <div className="lg:m-8">
      <Anchor href={url}>
        <div className="h-12 w-48 md:w-64 md:h-16 mb-2 mt-8 text-center">
          <Image
            src={logo}
            alt={companyName}
            height={height}
            width={aspectRatio * height}
            loader={imageLoader}
          />
        </div>
      </Anchor>
      <div className="font-bold mb-1">{title}</div>
      <div className="ml-2 text-gray-700 text-xs uppercase font-semibold tracking-wide">
        {duration}
      </div>
      <ul className="ml-4">
        {responsibilities.map((responsibility) => (
          <li className="text-gray-800 list-disc text-sm" key={responsibility}>
            {responsibility}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Company;
