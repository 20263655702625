import Link from "next/link";

interface IProps {
  href: string;
  children: React.ReactNode;
  ["aria-label"]?: string;
}

const Anchor = ({ href, children, ...props }: IProps) => {
  const isExternal = href.match(/^https?/);
  return isExternal ? (
    <Link href={href}>
      <a
        href={href}
        target={"_blank"}
        rel={"noopener"}
        aria-label={props["aria-label"]}
      >
        {children}
      </a>
    </Link>
  ) : (
    <Link href={href}>
      <a>{children}</a>
    </Link>
  );
};

export default Anchor;
