import { Email } from "@emotion-icons/material/Email";
import { Github } from "@emotion-icons/boxicons-logos/Github";
import { Linkedin } from "@emotion-icons/boxicons-logos/Linkedin";
import { Medium } from "@emotion-icons/boxicons-logos/Medium";
import { StackOverflow } from "@emotion-icons/boxicons-logos/StackOverflow";
import { Quora } from "@emotion-icons/boxicons-logos/Quora";
import { Twitter } from "@emotion-icons/boxicons-logos/Twitter";
import { Instagram } from "@emotion-icons/boxicons-logos/Instagram";
import { Soundcloud } from "@emotion-icons/boxicons-logos/Soundcloud";
import { Youtube } from "@emotion-icons/boxicons-logos/Youtube";

import Obfuscate from "react-obfuscate";
import Anchor from "./Util/Anchor";

interface IProps {
  size?: "small";
}

const SocialIcons = (props: IProps) => {
  const iconStyles = `${
    props.size === "small" ? "w-6" : "w-12"
  } m-1 inline-block`;
  return (
    <div className="w-full max-w-sm inline-block">
      <Anchor
        href="https://us17.list-manage.com/contact-form?u=062fcd140aaf62b5c7b4cde9f&form_id=ea42e5fd10a3a5edf81aa70f8aaff48d"
        aria-label="Email"
      >
        <div className={iconStyles}>
          <Email />
        </div>
      </Anchor>
      <Anchor href="https://github.com/xow" aria-label="Github">
        <div className={iconStyles}>
          <Github />
        </div>
      </Anchor>
      <Anchor
        href="https://www.linkedin.com/in/john-okely/"
        aria-label="LinkedIn"
      >
        <div className={iconStyles}>
          <Linkedin />
        </div>
      </Anchor>
      <Anchor href="https://medium.com/@jlokely" aria-label="Medium">
        <div className={iconStyles}>
          <Medium />
        </div>
      </Anchor>
      <Anchor
        href="https://www.stackoverflow.com/users/9222232/johnok"
        aria-label="Stack Overflow"
      >
        <div className={iconStyles}>
          <StackOverflow />
        </div>
      </Anchor>
      <Anchor
        href="https://www.quora.com/profile/John-Okely"
        aria-label="Quora"
      >
        <div className={iconStyles}>
          <Quora />
        </div>
      </Anchor>
      <Anchor href="https://twitter.com/jlokely" aria-label="Twitter">
        <div className={iconStyles}>
          <Twitter />
        </div>
      </Anchor>
      <Anchor href="http://instagram.com/jlokely" aria-label="Instagram">
        <div className={iconStyles}>
          <Instagram />
        </div>
      </Anchor>
      <Anchor href="https://soundcloud.com/pianokely" aria-label="Soundcloud">
        <div className={iconStyles}>
          <Soundcloud />
        </div>
      </Anchor>
      <Anchor href="https://www.youtube.com/user/orkahm52" aria-label="Youtube">
        <div className={iconStyles}>
          <Youtube />
        </div>
      </Anchor>
    </div>
  );
};

export default SocialIcons;
